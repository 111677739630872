import React from 'react';
import styled from 'styled-components';
import {Button, Input} from 'antd';
import {navigate} from 'gatsby';
import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import * as AppContext from '../../AppContext';
import {Avatar, LockOutlined, Facebook, Google} from '../../Widgets/Icon';
import SocialRegisterForm from './UserSocialRegisterForm';

const config = require('../../../data.json');

function RegisterForm(props) {
  const app = React.useContext(AppContext.Context);
  const [values, setValues] = React.useState({
    username: '',
    password: '',
    confirm_password: '',
  });
  const [socialSignin, setSocialSignin] = React.useState({
    facebook: false,
    google: false,
    data: '',
  });

  const getValue = (key) => values[key];
  const onValueChange = (key) => (e) => {
    setValues({...values, [key]: e.target.value});
  };

  function formValidation() {
    if (!values.username || !values.password || !values.confirm_password) {
      alert('帳號、密碼及確認密碼不得為空。');
      return false;
    }

    if (values.password !== values.confirm_password) {
      alert('設定密碼、確認密碼需相同。');
      return false;
    }

    if (values.password.length < 8) {
      alert('密碼長度不得小於8碼');
      return false;
    }

    return true;
  }

  async function onRegister() {
    if (!formValidation()) {
      return;
    }

    app.actions.setLoading(true);
    try {
      const {username, password} = values;
      await app.actions.memberRegister({username, password});
      await app.actions.memberLogin({username, password});
      navigate('/member');
    } catch (err) {
      throw err;
    }
    app.actions.setLoading(false);
  }

  const _responseGoogle = async (response) => {
    console.warn(response);
    if (response.tokenId) {
      setSocialSignin({...socialSignin, google: true, data: response});
    }
  };

  const _responseFacebook = async (response) => {
    console.warn(response);
    if (response.accessToken) {
      setSocialSignin({...socialSignin, facebook: true, data: response});
    }
  };

  return (
    <Wrapper>
      <form>
        <header>
          <img
            alt="logo"
            src="/images/logo.png"
            style={{width: 251, height: 67}}
          />
        </header>

        {!socialSignin.facebook && !socialSignin.google && (
          <>
            <label>帳號</label>
            <Input
              placeholder="身分證字號 / 居留證 / 護照號碼"
              prefix={<Avatar />}
              style={{marginBottom: 17, height: 40}}
              value={getValue('username')}
              onChange={onValueChange('username')}
            />
            <label>密碼</label>
            <Input.Password
              prefix={<LockOutlined color="#808080" />}
              placeholder={'請設定密碼'}
              style={{marginBottom: 15, height: 40}}
              value={getValue('password')}
              onChange={onValueChange('password')}
            />

            <Input.Password
              prefix={<LockOutlined color="#808080" />}
              placeholder={'請確認密碼'}
              style={{marginBottom: 30, height: 40}}
              value={getValue('confirm_password')}
              onChange={onValueChange('confirm_password')}
            />

            <Button
              type="primary"
              size="large"
              onClick={() => onRegister()}
              style={{marginBottom: 20, borderRadius: 4, fontSize: 14}}>
              註冊
            </Button>

            <GoogleLogin
              clientId={config.googleClientId}
              buttonText="Login"
              render={(renderProps) => (
                <SocialSignInBtn
                  css="background-color: #ffffff; border: 1px solid lightgray;"
                  disabled={renderProps.disabled}
                  onClick={renderProps.onClick}>
                  <div style={{flexBasis: 60, textAlign: 'center'}}>
                    <Google size={26} />
                  </div>
                  <div
                    style={{color: '#4a4a4a', marginLeft: 10, minWidth: 155}}>
                    使用 Google 帳號註冊
                  </div>
                </SocialSignInBtn>
              )}
              onSuccess={_responseGoogle}
              onFailure={_responseGoogle}
              cookiePolicy={'single_host_origin'}
            />

            <FacebookLogin
              appId={config.facebookAppId}
              callback={_responseFacebook}
              render={(renderProps) => (
                <SocialSignInBtn
                  css="background-color: #395899;"
                  onClick={renderProps.onClick}>
                  <div style={{flexBasis: 60, textAlign: 'center'}}>
                    <Facebook size={26} />
                  </div>
                  <div
                    style={{color: '#ffffff', marginLeft: 10, minWidth: 155}}>
                    使用 Facebook 帳號註冊
                  </div>
                </SocialSignInBtn>
              )}
            />
          </>
        )}

        {(socialSignin.facebook || socialSignin.google) && (
          <SocialRegisterForm
            facebook={socialSignin.facebook}
            google={socialSignin.google}
            data={socialSignin.data}
            onCancel={() =>
              setSocialSignin({facebook: false, google: false, data: ''})
            }
          />
        )}
      </form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;

  & > form {
    max-width: 350px;
    width: 100%;

    & > header {
      margin-bottom: 45px;
      display: flex;
      align-self: center;
      align-items: center;
    }

    margin: 20px auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;

    & > label {
      margin: 0px 10px 10px 10px;
      font-size: 14px;
      color: #6b6c6f;
      letter-spacing: 0.4px;
    }

    & > button {
      cursor: pointer;
      width: 100%;
      height: 40px;
      border: 0 solid #d9d9d9;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      :focus {
        outline: none;
      }
    }
  }
`;

const SocialSignInBtn = styled.div`
  cursor: pointer;
  width: 100%;
  height: 46px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0px;

  ${(props) => props.css || ''}
`;

export default RegisterForm;
