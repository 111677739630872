import React from 'react';
import styled from 'styled-components';
import {Button, Input} from 'antd';
import {navigate} from 'gatsby';

import * as AppContext from '../../AppContext';
import {Avatar, LeftOutlined} from '../../Widgets/Icon';

function SocialRegisterForm(props) {
  const app = React.useContext(AppContext.Context);
  const {facebook, google, data, onCancel} = props;
  const [values, setValues] = React.useState({
    username: '',
  });

  const getValue = (key) => values[key];
  const onValueChange = (key) => (e) => {
    setValues({...values, [key]: e.target.value});
  };

  function formValidation() {
    if (!values.username) {
      alert('身分證字號 / 居留證 / 護照號碼不得為空。');
      return false;
    }

    return true;
  }

  const onFacebookRegister = async () => {
    if (!formValidation()) {
      return;
    }

    app.actions.setLoading(true);
    try {
      await app.actions.memberRegisterByFacebook({
        token: data.accessToken,
        identity: values.username,
      });
      await app.actions.memberLoginByFacebook({token: data.accessToken});

      navigate('/member');
    } catch (err) {
      console.warn(err);
    }
    app.actions.setLoading(false);
  };

  const onGoogleRegister = async () => {
    if (!formValidation()) {
      return;
    }

    app.actions.setLoading(true);
    try {
      await app.actions.memberRegisterByGoogle({
        token: data.tokenId,
        identity: values.username,
      });
      await app.actions.memberLoginByGoogle({token: data.tokenId});

      navigate('/member');
    } catch (err) {
      console.warn(err);
    }
    app.actions.setLoading(false);
  };

  return (
    <Wrapper>
      <div className="back">
        <Button shape="circle" onClick={onCancel}>
          <LeftOutlined color="rgba(0, 0, 0, 0.65)" />
        </Button>
      </div>

      <form>
        <label>
          {`為確保您的資料安全，\n請輸入身分證字號 / 居留證 / 護照號碼`}
        </label>
        <Input
          placeholder="身分證字號 / 居留證 / 護照號碼"
          prefix={<Avatar />}
          style={{marginBottom: 17, height: 40}}
          value={getValue('username')}
          onChange={onValueChange('username')}
        />
        <Button
          type="primary"
          size="large"
          onClick={() => {
            if (facebook) {
              onFacebookRegister();
            } else {
              onGoogleRegister();
            }
          }}
          style={{borderRadius: 4, fontSize: 14}}>
          確認
        </Button>
      </form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > form {
    max-width: 350px;
    width: 100%;

    & > header {
      margin-bottom: 45px;
      display: flex;
      align-self: center;
      align-items: center;
    }

    margin: 20px auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;

    & > label {
      margin: 0px 10px 10px 10px;
      font-size: 14px;
      color: #6b6c6f;
      letter-spacing: 0.4px;
      white-space: pre;
    }

    & > button {
      cursor: pointer;
      width: 100%;
      height: 40px;
      border: 0 solid #d9d9d9;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      :focus {
        outline: none;
      }
    }
  }
`;

export default SocialRegisterForm;
