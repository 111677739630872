import React from 'react';
import styled from 'styled-components';
import {Button, Modal, Input, Checkbox} from 'antd';
import {navigate} from 'gatsby';
import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import * as AppContext from '../../AppContext';
import {Avatar, LockOutlined, Facebook, Google} from '../../Widgets/Icon';
import RegisterForm from './UserRegisterForm';
import ForgetPasswordForm from './UserForgetPasswordForm';

const queryString = require('query-string');
const config = require('../../../data.json');

function UserLandingPage(props) {
  const app = React.useContext(AppContext.Context);
  const [values, setValues] = React.useState({
    username: app.actions.memberGetUsername() || '',
    password: '',
    checked: false,
  });
  const [modal, setModal] = React.useState({type: null});

  const getValue = (key) => values[key];
  const onValueChange = (key) => (e) => {
    if (e.target.type === 'checkbox') {
      setValues({...values, [key]: e.target.checked});
    } else {
      setValues({...values, [key]: e.target.value});
    }
  };

  function formValidation() {
    if (!values.username || !values.password) {
      alert('帳號及密碼不得為空。');
      return false;
    }
    return true;
  }

  async function onLogin() {
    if (!formValidation()) {
      return;
    }

    app.actions.setLoading(true);
    try {
      const {username, password, checked} = values;
      await app.actions.memberLogin({username, password});
      app.actions.memberSetUsername(checked);
      navigate('/member');
    } catch (err) {
      const json = await err.json();
      if (err.status === 400 && json.code === 'incorrect_username_field') {
        alert('會員帳號格式不正確。');
      }

      if (err.status === 400 && json.code === 'incorrect_password_field') {
        alert('會員密碼不正確。');
      }

      if (err.status === 404 && json.code === 'user_not_found') {
        alert('尚未註冊該位會員。');
      }
    }
    app.actions.setLoading(false);
  }

  const _responseGoogle = async (response) => {
    console.warn(response);

    if (response.tokenId) {
      try {
        await app.actions.memberLoginByGoogle({token: response.tokenId});
        navigate('/member');
      } catch (err) {
        console.warn(err);
      }
    }
  };

  const _responseFacebook = async (response) => {
    console.warn(response);

    if (response.accessToken) {
      try {
        await app.actions.memberLoginByFacebook({token: response.accessToken});
        navigate('/member');
      } catch (err) {
        console.warn(err);
      }
    }
  };

  React.useEffect(() => {
    if (typeof window !== undefined) {
      const entries = window.location.href;
      const params = queryString.parse(window.location.search);
      const token = params.token;

      if (token && !modal.type) {
        window.localStorage.setItem('token', token);
        window.history.replaceState({}, document.title, entries.split('?')[0]);
        setModal({type: 'forget_password', uiState: 'set_new_password'});
      }
    }
  }, [modal.type, app.actions]);

  return (
    <Wrapper>
      <form>
        <header>
          <img
            alt="logo"
            src={'/images/logo.png'}
            style={{width: 251, height: 67}}
          />
        </header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 10,
            marginBottom: 25,
          }}>
          <span style={{color: '#6B6C6F', letterSpacing: 0.4}}>
            還不是會員嗎？
          </span>
          <Button
            type="text"
            style={{
              color: '#6B6C6F',
              letterSpacing: 0.4,
            }}
            onClick={() => setModal({type: 'register'})}>
            註冊
          </Button>
        </div>
        <label>帳號</label>
        <Input
          placeholder="身分證字號 / 居留證 / 護照號碼"
          prefix={<Avatar />}
          style={{marginBottom: 17, height: 40}}
          value={getValue('username')}
          onChange={onValueChange('username')}
        />
        <label>密碼</label>
        <Input.Password
          prefix={<LockOutlined color="#808080" />}
          placeholder={'請輸入密碼'}
          style={{marginBottom: 30, height: 40}}
          value={getValue('password')}
          onChange={onValueChange('password')}
        />
        <Button
          type="primary"
          size="large"
          onClick={() => onLogin()}
          style={{borderRadius: 4, fontSize: 14}}>
          登入
        </Button>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 15,
            marginBottom: 60,
          }}>
          <Checkbox
            checked={getValue('checked')}
            onChange={onValueChange('checked')}
            style={{color: '#6B6C6F', letterSpacing: 0.4}}>
            記住我
          </Checkbox>

          <div style={{flex: 1}}></div>

          <Button
            type="text"
            style={{color: '#6B6C6F', letterSpacing: 0.4}}
            onClick={() =>
              setModal({type: 'forget_password', uiState: 'send_email'})
            }>
            忘記密碼?
          </Button>
        </div>

        <GoogleLogin
          clientId={config.googleClientId}
          buttonText="Login"
          render={(renderProps) => (
            <SocialSignInBtn
              css="background-color: #ffffff; border: 1px solid lightgray;"
              disabled={renderProps.disabled}
              onClick={renderProps.onClick}>
              <div style={{flexBasis: 60, textAlign: 'center'}}>
                <Google size={26} />
              </div>
              <div style={{color: '#4a4a4a', marginLeft: 10, minWidth: 155}}>
                使用 Google 帳號登入
              </div>
            </SocialSignInBtn>
          )}
          onSuccess={_responseGoogle}
          onFailure={_responseGoogle}
          cookiePolicy={'single_host_origin'}
        />

        <FacebookLogin
          appId={config.facebookAppId}
          callback={_responseFacebook}
          render={(renderProps) => (
            <SocialSignInBtn
              css="background-color: #395899;"
              onClick={renderProps.onClick}>
              <div style={{flexBasis: 60, textAlign: 'center'}}>
                <Facebook size={26} />
              </div>
              <div style={{color: '#ffffff', marginLeft: 10, minWidth: 155}}>
                使用 Facebook 帳號登入
              </div>
            </SocialSignInBtn>
          )}
        />
      </form>

      <Modal
        key={modal.type}
        title={null}
        footer={null}
        width={600}
        bodyStyle={{padding: 0}}
        visible={!!modal.type}
        onCancel={() => setModal({type: null})}>
        {modal.type === 'register' && <RegisterForm />}
        {modal.type === 'forget_password' && (
          <ForgetPasswordForm uiState={modal.uiState} />
        )}
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;

  & > form {
    max-width: 350px;
    width: 100%;

    & > header {
      margin-bottom: 45px;
      display: flex;
      align-self: center;
      align-items: center;
    }

    margin: 20px auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;

    & > label {
      margin: 0px 10px 10px 10px;
      font-size: 14px;
      color: #6b6c6f;
      letter-spacing: 0.4px;
    }

    & > button {
      cursor: pointer;
      width: 100%;
      height: 40px;
      border: 0 solid #d9d9d9;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      :focus {
        outline: none;
      }
    }
  }
`;

const SocialSignInBtn = styled.div`
  cursor: pointer;
  width: 100%;
  height: 46px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0px;

  ${(props) => props.css || ''}
`;

export default UserLandingPage;
